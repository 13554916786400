<template>
  <section class="section hero is-fullheight is-error-section">
    <div class="hero-body">
      <div class="container">
        <div class="has-text-centered">
          <error-content title="This action is not allowed" icon="lock" @go-back="goBack"/>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import ErrorContent from '@/components/ErrorContent'
import CardComponent from '@/components/CardComponent'
import settings from "@/store/modules/settings.store";

export default {
  name: 'Error',
  components: {CardComponent, ErrorContent},
  props: {
    isInCard: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }

    this.$store.commit('settings/asideRightToggle', false)
    this.$store.commit('settings/fullPage', true)
  },
  beforeDestroy() {
    this.$store.commit('settings/fullPage', false)

  },

  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
